import { Button, Checkbox, Divider, Select, Space, Table } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import $api, { $apiInventory } from "../../http";
import TabPane from "antd/es/tabs/TabPane";
import { createPortal } from "react-dom";
import { columns, defaultSelectValue } from "./constants";
import PricePrintGoods from "./PricePrintGoods";
import ProductSelection from "./ProductSelection";

const getShops = async () => {
    try{
        //const data = await $apiInventory().get("shops");
        const data = await $api.get("/shops/manual/shops");
        const result = data.data.map(x=>({label: x.name, value: x.shopDbName}));
        result.push({label: '', value: ''});
        return result;
    }
    catch(e){ console.error(e); }
    return [{...defaultSelectValue}];
}

const getGroups = async (shopDbName) => {
    if(shopDbName=="" || shopDbName===undefined) return [{...defaultSelectValue}];
    try{
        //const result = await $apiInventory(shopDbName).get("goodgroups");
        const result = await $api.get('/shops/manual/good-groups',{
            headers: {shopDbName}
        });
        const data = result.data.map(gr=>({value: gr.id + "", label: gr.name}));
        data.push({...defaultSelectValue});
        return data;
    }
    catch(e){ console.error(e); };
    return [{...defaultSelectValue}];
}

const getItems = async (shopDbName, groupId, isActual=true) => {
    const resp = await $api.get(`/shops/manual/goods?isDeleted=false&limit=1000&groupId=${groupId}&isActual=${isActual}`,{
        headers: { shopDbName }
    });
    return resp.data.map(x=>({
        ...x, balance:0
    }));
}

var selectGoodsRef = null;

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectGoodsRef.current = selectedRows;
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

function PricePrintChooseGoods(){
    const shopsListRef = useRef([]);
    const [shops, setShops] = useState([]);
    const [shopDbName, setShopDbName] = useState('');
    const [shop, setShop] = useState([{...defaultSelectValue}]);
    const [groups, setGroups] = useState([{...defaultSelectValue}]);
    const [groupId, setGroupId] = useState("");
    const [quantityGrateZeroFlag, setQuantityGrateZeroFlag] = useState(false);
    const [isActualGoods, setIsActualGoods] = useState(true);
    const [items, setItems] = useState([]);
    const [isOpenPrintWindow, setIsOpenPrintWindow] = useState();
    const [printWindow, setPrintWindow] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const typePrint = useRef("cashier");

    useEffect(()=>{
        getShops().then(setShops).catch(e=>{
            console.error(e);
            setShops(...defaultSelectValue);
        })
    }, []);

    useEffect(()=>{
        setGroupId("");
        getGroups(shopDbName).then(setGroups).catch(e=>{
            console.error(e);
            setGroupId('');
        })
    }, [shopDbName]);

    useEffect(() => {
        getItems(shopDbName, groupId, isActualGoods).then(setItems).catch(console.error);
    }, [shopDbName, groupId, isActualGoods]);

    const print = (_typePrint) => {
        /*
        const newWindow = window.open();
        setPrintWindow(newWindow);
        */
        typePrint.current = _typePrint;
        setIsOpenPrintWindow(true);
    }

    const selectedRowsHandler = useCallback((selectedRowKeys, selectedRows) =>{
        setSelectedItems(selectedRows.map(s=>({...s}) ));
    }, [shop.shopDbName, groupId]);

    const addItemsAfterSearch = (addItems) => 
        setItems([...items, ...addItems]);
    
    return <div>
        <div>
            <Space wrap>
                Мгазин:
                <Select options={shops} defaultValue={''} onChange={setShopDbName} style={{width: "150px"}}/>
                Группа (актуальные <Checkbox defaultChecked="true" onChange={e=>setIsActualGoods(e.target.checked)}/>):
                <Select options={groups} defaultValue={groupId} onChange={e=>setGroupId(e)} style={{width: "150px"}}/>
                <ProductSelection addGoods={addItemsAfterSearch} shopDbName={shopDbName}/>
                <Button onClick={_=>setItems([])}>Очистить</Button>
                <Button onClick={_=>print("cashier")}>Печать для кассира</Button>
                <Button onClick={_=>print("print1")}>Печать витрина</Button>
            </Space>
            <Divider size="small"/>
            <Table columns={columns} dataSource={items} size="small" 
            pagination={false}
            rowKey={e=>e.uuid}
                rowSelection={{
                    type: "checkbox",
                    onChange: selectedRowsHandler
                    /*...rowSelection,*/
                  }}
                  />
            {isOpenPrintWindow && <PricePrintGoods items={selectedItems} shopDbName={shopDbName} typePrint={typePrint.current} setIsOpenPrintWindow={setIsOpenPrintWindow} /> }
            
        </div>
    </div>
}

export default PricePrintChooseGoods;