import { Button } from "antd"
import dayjs from "dayjs"
import { useEffect, useLayoutEffect, useRef } from "react"
import Barcode from "react-barcode"
import { createPortal } from "react-dom"

const PricePrintGoods = ({items, shopDbName, typePrint, setIsOpenPrintWindow }) => {
    const newWindowRef = window.open();
    console.log({items, shopDbName});
    useEffect(()=>{
        const timer = setTimeout(()=>{
            newWindowRef.print();
            setIsOpenPrintWindow(false);
        },1_000);
        return ()=>newWindowRef.close();
    }, []);

    return createPortal(
        <div style={{backgroundColor: "white", width: "100vw", minHeight: "100vh", margin: "0", padding: "0"}}>
            <div style={{display: "grid", gridTemplateColumns: "repeat(3, 70mm)"}}>{
                items.map(item => {
                    return item.barcodes.map(b=>{
                        const summary = {...item, name: item.article==='' || item.article===null ? item.name : item.article};
                        summary.orgName= 'ИП Павлова Е. А.';
                        summary.inn = '310303279500';
                        if(['Razumnoe_new', 'Shop9', 'Shop6'].indexOf(shopDbName)>-1){
                            summary.orgName= 'ИП Санин Д. Р.';
                            summary.inn = '312347367035';
                        }
                        if(typePrint==="cashier")
                            return <PrintCashier barcode={b} {...summary} />
                        if(item.unit!==796)
                            return <PrintGramm barcode={b} {...summary} />
                        //if(typePrint==="printGramm")
                        return <Print1 barcode={b} {...summary} />
                    })
                })
            }
            </div>
        </div>,
        newWindowRef.document.body
    );
     
}

const PrintCashier = ({orgName, inn, name, price, unitStr, barcode}) => <div style={{border: "dotted 1px lightgray", padding: "2mm"}}>
        {name}<br/>
        <Barcode height={10} value={barcode} displayValue={false} />
    </div>;

const PrintGramm = ({orgName, inn, name, price, unit, barcode}) => 
    <div style={{border: "dotted 1px lightgray", padding: "2mm", pageBreakInside: "avoid", fontSize: "14pt"}}>
        <h5 style={{width: "56mm", margin:"0", overflowX: "hidden", minHeight: "15mm", textOverflow: "ellipsis"}}>{name}</h5>
        <div style={{clear: "both"}} />
        <Barcode height={10} width={1} format="CODE128" value={barcode} displayValue={false} />
        <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)"}}>
            <h4 style={{fontSize: "18pt", margin: "0"}}>
                {Math.ceil(price / 10)}
                <span style={{fontSize: "10pt", float: "right", paddingTop: "1em", marginRight: "1em"}}>р. 100гр</span>
            </h4>
            <span style={{fontSize: "6pt", display: "block"}}>{orgName}<br />{inn}<br />{dayjs().format("DD.MM.YY")}</span>
        </div>
    </div>;

const Print1 = ({orgName, inn, name, price, unit, unitStr, barcode}) => 
    <div style={{border: "dotted 1px lightgray", padding: "2mm", pageBreakInside: "avoid", fontSize: "14pt"}}>
        <h5 style={{width: "56mm", margin:"0", overflowX: "hidden", minHeight: "15mm", textOverflow: "ellipsis"}}>{name}</h5>
        <div style={{clear: "both"}} />
        <Barcode height={10} width={1} format="CODE128" margin={0} value={barcode} displayValue={false} />
        <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)"}}>
            <h4 style={{fontSize: "18pt", margin: "0"}}>
                {price}
                <span style={{fontSize: "10pt", float: "right", paddingTop: "1em", marginRight: "1em"}}>р. {unitStr}</span>
            </h4>
            <span style={{fontSize: "6pt", display: "block"}}>{orgName}<br />{inn}<br />{dayjs().format("DD.MM.YY")}</span>
        </div>
    </div>;

export default PricePrintGoods;