import { useEffect, useState } from "react";
import $api from "../../../http";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { Button, Space, Switch, Table } from "antd";
import useInventoryDetail from "./components/InventoryDetailComponent";
import useChangeQuantityFactModal from "./components/ChangeQuantityFactDialog";

const getSummary = async (shopDbName, inventoryId, viewZeroPositions=false) => {
    const resp = await $api.get(`/shops/inventory/${inventoryId}/summary`,{
        headers: {shopDbName}
    });
    const result = resp.data;
    if(viewZeroPositions===false)
        return {
            ...result, 
            positions: result.positions
                .filter(x=>x.quantityFact!==0 && x.quantityDb!==0)
        };
    return result;
}

function InventorySummary(){
    const {inventoryId} = useParams();
    const {state} = useLocation();
    const { shopDbName } = state;
    const [summary, setSummary] = useState(null);
    const navigate = useNavigate();
    const [detailModal, openDetailModal] = useInventoryDetail();
    const [openChangeQuantityModal, changeQuantityModal] = useChangeQuantityFactModal(shopDbName, inventoryId, setSummary);
    const [viewZeroPositions, setViewZeroPositions] = useState(false);

    useEffect(()=>{
        getSummary(shopDbName, inventoryId, viewZeroPositions).then(setSummary).catch(console.error);
    }, [inventoryId, shopDbName, viewZeroPositions]);

    if(summary===null) return <></>;
    const onCell = (record, rowIndex) => {
        return {
          onClick: (event) => {
            openDetailModal(shopDbName, inventoryId, record.goodId)
          }, 
        };
      };
    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            width: 300,
            fixed: 'left',
            onCell,
        },
        {
            title: 'Фактически',
            dataIndex: 'quantityFact',
            key: 'quantityFact',
            width: 40,
            onCell: (record, rowIndex) => {
                return {
                  onClick: (event) => {
                    openChangeQuantityModal(record.goodId);
                  }, 
                };
              },
        },
        {
            title: 'В прогр.',
            dataIndex: 'quantityDb',
            key: 'quantityDb',
            width: 40,
            onCell,
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price',
            width: 40,
            onCell,
        },
        {
            title: 'Недосдача',
            dataIndex: 'quantityMinus',
            key: 'quantityMinus',
            width: 40,
            render: (_, item) =>{
                if(item.quantityFact - item.quantityDb >= 0) return <></>;
                return <div style={{color: 'red', fontWeight: '600'}}>
                    {(item.quantityDb - item.quantityFact).toFixed(3)}
                </div>;
            },
            onCell,
        },
        {
            title: 'Пересорт',
            dataIndex: 'quantityPlus',
            key: 'quantityPlus',
            width: 40,
            render: (_, item) =>{
                if(item.quantityFact - item.quantityDb <= 0) return <></>;
                return <div style={{color: 'green', fontWeight: '600'}}>
                    {(item.quantityFact - item.quantityDb).toFixed(3)}
                </div>;
            },
            onCell,
        },
    ]

    return <>
        {detailModal}
        {changeQuantityModal}
        <div style={{marginBottom: '10px'}}>
            <div>
                <Space>
                    <div  style={{marginRight: "20px"}}>
                        <Button onClick={_=>navigate('/shops/inventory-list')} type="primary" size="small">назад</Button>
                    </div>
                    <div>
                        <Switch defaultChecked={viewZeroPositions} onChange={_=>{setViewZeroPositions(prev=>!prev)}} size="small" />
                        &nbsp;&nbsp;Отображать позиции с 0 остатками
                    </div>
                </Space>
            </div>
            <hr style={{margin: "2px", color: "lightgray"}}/>
            <Space>
                <span style={{marginRight: "10px"}}>
                    Дата: {dayjs(Date.parse(summary.createAt)).format('DD.MM.YY')} 
                </span>
                <span style={{marginRight: "10px"}}>
                    Статус: {summary.status===2 && <>Завершен </>} 
                    Сумма фактически: {summary.amountFact} в  программе {summary.amountDb}
                </span>
            </Space>
        </div>
        <Table 
            columns={columns} 
            dataSource={summary.positions} 
            rowKey={"id"} 
            pagination={false}  
            size="small"
            scroll={{
                x: true,
                y: 'calc(100vh - 120px)',
              }}
            onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    //openDetailModal(shopDbName, inventoryId, record.goodId)
                },
                  onDoubleClick: (event) => {}, // click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
            />
    </>
}

export default InventorySummary;